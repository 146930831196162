// categoryHandler
export const CategoryHandler = () => {
  document
    .querySelector('.category__list')
    ?.querySelectorAll('input[type=radio]')
    ?.forEach((item) => {
      item.addEventListener('click', (e) => {
        let categoryInput = document.querySelector('#categoryVar').textContent

        if (item.checked) {
          categoryInput = item.id
        }

        document.querySelector(
          '#categoryVar'
        ).textContent = categoryInput.trim()
      })
    })
}
// categoryHandler
