// mobile
export const Mobile = () => {
  if (document.querySelector('.mobile__cta')) {
    document.querySelector('.mobile__cta').addEventListener('click', () => {
      document.querySelector('.form')?.classList.add('mobile__form')
      document.body.style.overflow = 'hidden'
    })
  }

  if (document.querySelector('.form__back')) {
    document.querySelector('.form__back').addEventListener('click', () => {
      document.querySelector('.form')?.classList.remove('mobile__form')
      document.body.style.overflow = ''
    })
  }

  window.addEventListener('resize', (e) => {
    if (document.documentElement.clientWidth > 767) {
      document.querySelector('.form')?.classList.remove('mobile__form')
      document.body.style.overflow = ''
    }
  })
}
// mobile
