// import Swiper bundle with all modules installed
import Swiper from 'swiper/bundle'

export const ListingPage = () => {
  // init slider
  var swiper = new Swiper('.swiper-container--listing', {
    loop: true,
    spaceBetween: 10,
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
      renderBullet: function (index, className) {
        return '<span class="' + className + '">' + (index + 1) + '</span>'
      }
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    }
  })

  const mapFloatBtn = document.querySelector('.map__float-btn')
  const listingLeft = document.querySelector('.listing__left')
  const mapCloseBtn = document.querySelector('.map__close-btn')

  if (document.querySelector('.listing')) {
    const url = window.location.href
    if (url.indexOf('map=true') !== -1) {
      document.body.classList.toggle('hidden')
      listingLeft.classList.toggle('listing__left--active')
      mapCloseBtn.classList.add('map__close-btn--active')
    }
  }

  mapFloatBtn &&
    mapFloatBtn.addEventListener('click', () => {
      document.body.classList.toggle('hidden')
      listingLeft.classList.toggle('listing__left--active')
      mapCloseBtn.classList.add('map__close-btn--active')
    })

  mapCloseBtn &&
    mapCloseBtn.addEventListener('click', () => {
      document.body.classList.toggle('hidden')
      listingLeft.classList.toggle('listing__left--active')
      mapCloseBtn.classList.remove('map__close-btn--active')
    })

  // init slider
}
