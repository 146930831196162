// import Swiper styles
import 'swiper/swiper-bundle.css'

import './library/svg-inject.min'
import './js/svg-inject'

import { windowEvents } from './js/windowEvents'
import { Header } from './js/header'
import { Dropdown } from './js/dropdown'
import { DatePicker } from './js/datepicker'
import { CityHandler } from './js/cityHandler'
import { CategoryHandler } from './js/categoryHandler'
import { Mobile } from './js/mobile'

// pages scripts
import { ListingPage } from './js/listing'
import { Scooters } from './js/scooters'
import { CardPage } from './js/card'
import { HostPage } from './js/host'
import { ChatPage } from './js/chat'

// wait for document loaded
window.addEventListener('load', function (event) {
  Header()
  Dropdown()
  CityHandler()
  CategoryHandler()
  DatePicker('startRange', 'endRange')

  const moreBtn = document.querySelector('.more-button__handler')
  if (moreBtn) {
    moreBtn.addEventListener('click', function () {
      document.querySelector('.list-container').classList.toggle('active')
    })
  }

  Mobile()

  ListingPage()
  Scooters()
  CardPage()
  HostPage()
  ChatPage()

  // handle all window event (click outside of items etc)
  windowEvents()
})
