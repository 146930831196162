export const Dropdown = () => {
  // dropdown
  document.querySelector('.dropdown__btn')?.addEventListener('click', () => {
    document.querySelector('.dropdown__content').classList.toggle('show')
  })

  document.querySelector('#cityHandler')?.addEventListener('click', () => {
    document.querySelector('.city__list').classList.toggle('show')
  })

  document.querySelector('#categoryHandler')?.addEventListener('click', () => {
    document.querySelector('.category__list').classList.toggle('show')
  })

  document.querySelector('.more')?.addEventListener('click', () => {
    document.querySelector('.more__list').classList.toggle('show')
  })

  document.querySelectorAll('.category__item')?.forEach((item) =>
    item?.addEventListener('click', (e) => {
      e.stopImmediatePropagation()
    })
  )

  document.querySelectorAll('.more__item')?.forEach((item) =>
    item?.addEventListener('click', (e) => {
      e.stopImmediatePropagation()
    })
  )
  // dropdown
}
