// cityHandler
export const CityHandler = () => {
  document.querySelectorAll('.city__item')?.forEach((item) => {
    item.addEventListener('click', (e) => {
      const textContent = item.querySelector('span').textContent

      document.querySelector('#city').value = textContent
    })
  })
}
// cityHandler
