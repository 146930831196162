export const Scooters = () => {
  let modalPayment = document.getElementById('modalPayment')
  let modalPaymentClose = document.getElementById('modalPaymentClose')

  let listingConfirmModal = document.getElementById('listingConfirmModal')
  let listingConfirmClose = document.getElementById('listingConfirmClose')

  if (document.querySelector('.listing')) {
    const url = window.location.href

    if (url.indexOf('booking=true') !== -1) {
      modalPayment.style.display = 'block'
      modalPayment.classList.add('modal--payment')
    }

    if (url.indexOf('confirm=true') !== -1) {
      listingConfirmModal.style.display = 'block'
    }
  }

  if (modalPaymentClose) {
    modalPaymentClose.onclick = function () {
      modalPayment.style.display = 'none'
      modalPayment.classList.remove('modal--payment')
    }
  }

  if (listingConfirmClose) {
    listingConfirmClose.onclick = function () {
      listingConfirmModal.style.display = 'none'
    }
  }

  const pricePerHour = document.getElementById('pricePerHour')
  const totalScooterPrice = document.getElementById('totalScooterPrice')

  if (modalPayment && modalPayment.classList.contains('modal--payment')) {
    if (pricePerHour.value) {
      totalScooterPrice.value = +pricePerHour.value
    }

    const discountsEnum = {
      1: 0,
      2: 0.05,
      3: 0.1,
      4: 0.15,
      5: 0.2,
    }

    document.getElementsByName('hour').
      forEach(h => h.addEventListener('click', (e) => {
        const priceInSelectedRange = parseInt(pricePerHour.value) * parseInt(e.target.value)

        totalScooterPrice.value = priceInSelectedRange -
          (priceInSelectedRange * discountsEnum[`${e.target.value}`])
      }))
  }

  const previousButton = document.getElementById('previous')
  const nextButton = document.getElementById('next')
  const submitButton = document.getElementById('validate')
  const dots = document.getElementsByClassName('progress-bar__dot')
  document.querySelectorAll('.step')
  const numberOfSteps = document.querySelectorAll('.step').length
  let currentStep = 1

  for (let i = 0; i < dots.length; ++i) {
    dots[i].addEventListener('click', () => {
      goToStep(i + 1)
    })
  }

  if (previousButton) {
    previousButton.onclick = goPrevious
  }
  if (nextButton) {
    nextButton.onclick = goNext
  }

  function goNext (e) {
    e.preventDefault()
    currentStep += 1
    goToStep(currentStep)
  }

  function goPrevious (e) {
    e.preventDefault()
    currentStep -= 1
    goToStep(currentStep)
  }

  function goToStep (stepNumber) {
    if (modalPaymentClose) {
      modalPaymentClose.scrollIntoView({ block: 'start', behavior: 'smooth' })
    }

    currentStep = stepNumber

    let inputsToHide = document.getElementsByClassName('step')
    let inputs = document.getElementsByClassName(`step${currentStep}`)
    let indicators = document.getElementsByClassName('progress-bar__dot')

    for (let i = indicators.length - 1; i >= currentStep; --i) {
      indicators[i].classList.remove('full')
    }

    for (let i = 0; i < currentStep; ++i) {
      indicators[i].classList.add('full')
    }

    //hide all input
    for (let i = 0; i < inputsToHide.length; ++i) {
      hide(inputsToHide[i])
    }

    //only show the right one
    for (let i = 0; i < inputs.length; ++i) {
      show(inputs[i])
    }

    //if we reached final step
    if (currentStep === numberOfSteps) {
      enable(previousButton)
      disable(nextButton)
      show(submitButton)
    }

    //else if first step
    else if (currentStep === 1) {
      disable(previousButton)
      enable(next)
      hide(submitButton)
    } else {
      enable(previousButton)
      enable(next)
      hide(submitButton)
    }
  }

  function enable (elem) {
    elem.classList.remove('disabled')
    elem.disabled = false
  }

  function disable (elem) {
    elem.classList.add('disabled')
    elem.disabled = true
  }

  function show (elem) {
    elem.classList.remove('hidden')
  }

  function hide (elem) {
    elem.classList.add('hidden')
  }
}
