// import Swiper bundle with all modules installed
import Swiper from 'swiper/bundle'
import { DatePicker } from './datepicker'

export const CardPage = () => {
  if (document.querySelector('.card')) {
    document.querySelector('.hero__form').classList.add('hide')
    DatePicker( 'fromRange', 'toRange', 1, 1, changeDates, 'changeDataSubmitBtn')

    const url = window.location.href
    if (url.indexOf('modal=true') !== -1) {
      document.getElementById('bookModal').style.display = 'block'
    }
  }

  // init slider
  var galleryThumbs = new Swiper('.gallery-thumbs', {
    spaceBetween: 10,
    slidesPerView: 4,
    loopedSlides: 4, //looped slides should be the same
    watchSlidesVisibility: true,
    watchSlidesProgress: true
  })
  var galleryTop = new Swiper('.gallery-top', {
    spaceBetween: 10,
    loopedSlides: 4, //looped slides should be the same
    navigation: {
      nextEl: '.swiper-button-next-card',
      prevEl: '.swiper-button-prev-card'
    },
    thumbs: {
      swiper: galleryThumbs
    }
  })

  // modal share
  var modal = document.getElementById('myModal')
  var btn = document.getElementById('myBtn')
  var span = document.getElementById('shareClose')

  if (btn) {
    btn.onclick = function () {
      modal.style.display = 'block'
    }
  }

  if (span) {
    span.onclick = function () {
      modal.style.display = 'none'
    }
  }
  // modal share

  // modal rews
  var modalRews = document.getElementById('rewsModal')
  var rewMoreBtn = document.getElementById('rewMore')
  var rewsClose = document.getElementById('rewsClose')

  if (rewMoreBtn) {
    rewMoreBtn.onclick = function () {
      modalRews.style.display = 'block'
    }
  }

  if (rewsClose) {
    rewsClose.onclick = function () {
      modalRews.style.display = 'none'
    }
  }
  // modal rews

  // book rews
  var bookModal = document.getElementById('bookModal')
  var bookCtaBtn = document.getElementById('bookCtaBtn')
  var bookClose = document.getElementById('bookClose')

  if (bookCtaBtn) {
    bookCtaBtn.onclick = function () {
      bookModal.style.display = 'block'
    }
  }

  if (bookClose) {
    bookClose.onclick = function () {
      bookModal.style.display = 'none'
    }
  }
  // book rews

  // fetch
  async function changeDates(id) {
    document.getElementById(id).click();
  }
}
