// sticky header
export const Header = () => {
  const navbar = document.getElementById('navbar')
  if (!navbar.classList.contains('navbar--listing')) {
    var prevScrollPos = window.pageYOffset

    window.onscroll = function () {
      var currentScrollPos = window.pageYOffset

      if (prevScrollPos > currentScrollPos) {
        // document.getElementById('navbar').style.top = '0'
        // document.getElementById('navbar').classList.add('active')
        document.querySelector('.dropdown__content').classList.remove('show') // dropdown
      } else {
        // document.getElementById('navbar').style.top = '-100px'
        // document.getElementById('navbar').classList.remove('active')
        document.querySelector('.dropdown__content').classList.remove('show') // dropdown
      }

      prevScrollPos = currentScrollPos
    }
  }
}
// sticky header
