export const windowEvents = () => {
  window.onclick = function (event) {
    if (event.target === document.getElementById('rewsModal')) {
      document.getElementById('rewsModal').style.display = 'none'
    }

    if (event.target === document.getElementById('myModal')) {
      document.getElementById('myModal').style.display = 'none'
    }

    if (event.target === document.getElementById('bookModal')) {
      document.getElementById('bookModal').style.display = 'none'
    }

    if (event.target === document.getElementById('modalPayment')) {
      document.getElementById('modalPayment').style.display = 'none'
      document.getElementById('modalPayment').classList.remove('modal--payment')
    }

    if (event.target === document.getElementById('listingConfirmModal')) {
      document.getElementById('listingConfirmModal').style.display = 'none'
    }

    if (!event.target.matches('.dropdown__action')) {
      document.querySelector('.dropdown__content')?.classList.remove('show')
    }

    if (!event.target.matches('#cityHandler')) {
      document.querySelector('.city__list')?.classList.remove('show')
    }

    if (!event.target.matches('#categoryHandler')) {
      document.querySelector('.category__list')?.classList.remove('show')
    }

    if (!event.target.matches('.more')) {
      document.querySelector('.more__list')?.classList.remove('show')
    }

    if (!event.target.matches('.more-button__handler')) {
      document.querySelector('.list-container').classList.remove('active')
    }
  }

  // lets
  const isCard = document.querySelector('main.card')

  window.addEventListener('resize', (e) => {
    if (isCard && window.innerWidth <= 767) {
      document.querySelector('footer.footer').style.paddingBottom = '90px'
    } else {
      document.querySelector('footer.footer').style.paddingBottom = '0'
    }
  })
}
