import Litepicker from 'litepicker'

export const DatePicker = (
  fromId = 'startRange',
  toId = 'endRange',
  col = 2,
  months = 2,
  cb = () => {},
  obj = null
) => {
  const today = new Date()
  const yesterday = new Date(today)
  yesterday.setDate(yesterday.getDate() - 1)

  let numberOfColumns = col
  let numberOfMonths = months
  if (document.documentElement.clientWidth < 768) {
    numberOfColumns = 1
    numberOfMonths = 1
  }

  // also we need check it on resize
  window.addEventListener('resize', (e) => {
    if (document.documentElement.clientWidth < 768) {
      numberOfColumns = 1
      numberOfMonths = 1
    }
  })

  const range = new Litepicker({
    element: document.getElementById(fromId),
    elementEnd: document.getElementById(toId),
    singleMode: false,
    startDate: new Date(),
    tooltipText: {
      one: 'day',
      other: 'days'
    },
    tooltipNumber: (totalDays) => {
      return totalDays - 1
    },
    minDate: yesterday,
    minDays: 2,
    maxDays: 60,
    delimiter: '-',
    format: 'DD-MM-YYYY',
    numberOfMonths,
    numberOfColumns,
    setup: (picker) => {
      picker.on('selected', (date1, date2) => {
        document.getElementById(fromId).value = date1.dateInstance
        document.getElementById(toId).value = date2.dateInstance

        if (cb && obj) {
          cb(obj)
        }
      })
    }
  })
  // datepicker
}
